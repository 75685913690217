<template>
    <div class="md-layout md-gutter md-alignment-space-around-space-between wrap">
        <md-card class="md-layout-item md-size-100 startnow">
            <md-card-header style="">
                    <span class="md-title">
                        Herzlich willkommen bei PAWblueprint!
                    </span>
            </md-card-header>

            <md-card-content class="md-layout md-gutter">
                <div class="md-layout-item md-size-20 md-small-size-30" style="text-align: left">
                    <img src="../assets/start_common/welcome.jpg" alt="" />
                </div>
                <div class="md-layout-item md-size-60 md-small-size-70">
                    <div style="width: fit-content; text-align: left; margin: auto">
                        Auf unserer Online-Plattform können Sie
                        <ul style="margin-block: 0">
                            <li>kontaktlos</li>
                            <li>einfach</li>
                            <li>schnell</li>
                            <li>umweltfreundlich</li>
                            <li>und ressourcenschonend</li>
                        </ul>
                        Ihren Fußabdruck von zuhause aus übermitteln.
                    </div>
                </div>
                <div class="md-layout-item md-size-20 md-small-hide" style="text-align: left">
                    &nbsp;
                </div>
                <!--
                <div v-show="$vssWidth <= 960" style="margin: auto">
                    Auf unserer Online-Plattform können Sie kontaktlos, einfach, schnell, umweltfreundlich
                    und ressourcenschonend Ihren Fußabdruck von zuhause aus übermitteln.
                </div>
                -->
            </md-card-content>
            <md-card-actions>
                <md-button class="md-raised md-primary" to="/register/user">
                    Los!
                </md-button>
            </md-card-actions>
        </md-card>

        <div class="md-layout-item md-size-100">
            <br>
        </div>

        <md-card class="md-layout-item md-size-30 md-small-size-100 manual">
            <md-card-header style="display: flex; flex-grow: 1;">
                <div class="md-subhead" style="margin: auto">1. Einloggen und Fußabdrücke nehmen</div>
            </md-card-header>

            <md-card-content>
                <img src="../assets/user_start/manual1.jpg" class="manualimg" alt="" />
            </md-card-content>
        </md-card>

        <md-card class="md-layout-item md-size-30 md-small-size-100 manual">
            <md-card-header style="display: flex; flex-grow: 1;">
                <div class="md-subhead" style="margin: auto">2. PAW Daten abschicken</div>
            </md-card-header>

            <md-card-content>
                <img src="../assets/user_start/manual2.jpg" class="manualimg" alt="" />
            </md-card-content>
        </md-card>

        <md-card class="md-layout-item md-size-30 md-small-size-100 manual">
            <md-card-header style="display: flex; flex-grow: 1;">
                <div class="md-subhead" style="margin: auto">3. Gewünschten Businesspartner kontaktieren und bald passgenau wohlfühlen</div>
            </md-card-header>

            <md-card-content>
                <img src="../assets/user_start/manual3.jpg" class="manualimg" alt="" />
            </md-card-content>
        </md-card>
    </div>
</template>

<style lang="scss" scoped>
.wrap {
    width: 90%;
    margin: auto;
}

.startnow {
    background-color: rgba(255, 255, 255, 0.5);
}

.manual {
    background-color: rgba(255, 180, 0, 0.5);
    font-weight: bold;
    display:flex;
    justify-content: flex-end;
    flex-direction: column;
}

.manual > .md-card-header > .md-subhead {
    font-weight: bold;
    opacity: 1;
    color: white;
}

.manualimg {
    width: 80%;
}

.md-title {
    text-align: left;
}

.md-content {
    text-align: left;
}
</style>

<script>
import VueScreenSize from 'vue-screen-size';

export default {
    name: 'User_Start',
    mixins: [VueScreenSize.VueScreenSizeMixin],
    props: {},
    components: {},
};
</script>
